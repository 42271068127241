module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Apollon Restaurant","short_name":"Apollon","description":"Description","start_url":"/","icon":"src/images/icon.png","background_color":"#18191a","theme_color":"#18191a","display":"fullscreen","theme_color_in_head":false,"crossOrigin":"use-credentials","orientation":"portrait","lang":"de","categories":["food"],"dir":"ltr","legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"4a3de672bf5df024c8fc4b5cbcc0fe80"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.apollon-restaurant.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-TZ9V6JSYXZ"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
